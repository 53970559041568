.App {
  text-align: center;
  background-image: url('./assests//bg.png');
  background-position: center;
  background-size: cover;
  height: 75vh;
}
.Contact {
  text-align: center;
  background-image: url('./assests//Contactus.png');
  background-position: center;
  background-size: cover;
  height: 100%;
}
.footer{
   text-align: center;
  background-image: url('./assests//FooterBg.png');
  background-position: center;
  background-size: cover;
  height: 10vh;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.mapContainerContact{
  border-radius: 10px;
background: linear-gradient(180deg, rgba(235, 162, 21, 0.74) 0%, rgba(235, 162, 21, 0.00) 100%);
backdrop-filter: blur(14.5px);
}
.formContainer{
  border-radius: 10px;
backdrop-filter: blur(14.5px);
width: 50vw;
padding: 20px;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
@media screen and (max-width: 768px) {
  .App {
    height: 40vh; /* Adjust the height to fit the entire viewport for mobile screens */
  }
}
@media screen and (max-width: 1000px) {
  .App {
    height: 40vh; /* Adjust the height to fit the entire viewport for mobile screens */
  }
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
